import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Faq from '../../components/Faq';

import imgTefl from '../../assets/images/faq-page/TEFL Courses.svg';
import imgVisas from '../../assets/images/faq-page/Student visas.svg';
import imgAccomodation from '../../assets/images/faq-page/Accommodation.svg';
import imgBarcelona from '../../assets/images/faq-page/Accommodation (1).svg';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/faq-page.scss';

const FaqPage = ({ data }) => {
  const [courseType, setCourseType] = useState('tefl-courses');
  const allPrismicFaqs = data.allPrismicFaqs.edges[0]?.node?.data;

  return (
    <Layout
      title={'FAQs about our Spanish Language Courses & Lessons'}
      description={'FAQs about our courses, accommodation, student visas and payment terms.'}
    >
      <div className="faq-page">
        <section className="c-first-section c-first-section--md">
          <div className="container courses-section">
            <h1 className="title">
              <FormattedMessage id="faqs.sec1.title" />
            </h1>
            <p className="description">
              <FormattedMessage id="faqs.sec1.description" />
            </p>
            <div className="courses-section__btns row">
              <div className="col-lg-3 col-md-6 col-12">
                <button
                  onClick={() => setCourseType('tefl-courses')}
                  className={`btn ${courseType === 'tefl-courses' ? 'btn--active' : ''}`}
                >
                  <img src={imgTefl} alt="ILA Courses" />
                  Spanish Courses
                </button>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <button
                  onClick={() => setCourseType('student-visas')}
                  className={`btn ${courseType === 'student-visas' ? 'btn--active' : ''}`}
                >
                  <img src={imgVisas} alt="Student Visas" />
                  Visas
                </button>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <button
                  onClick={() => setCourseType('accomodation')}
                  className={`btn ${courseType === 'accomodation' ? 'btn--active' : ''}`}
                >
                  <img src={imgAccomodation} alt="Accommodation" />
                  Accommodation
                </button>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <button
                  onClick={() => setCourseType('working-in-barcelona')}
                  className={`btn ${courseType === 'working-in-barcelona' ? 'btn--active' : ''}`}
                >
                  <img src={imgBarcelona} alt="Working in Spain" />
                  Payment
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section c-faqs-section">
          <div className="container">
            <div className={`inner-wrapper ${courseType === 'tefl-courses' ? 'active' : ''}`}>
              <div className="centered-faq">
                {allPrismicFaqs.ila_courses.map(
                  (item, index) =>
                    item.title.text && (
                      <Faq key={index} title={item.title.text} description={item.content.html} />
                    )
                )}
              </div>
            </div>
            <div className={`inner-wrapper ${courseType === 'student-visas' ? 'active' : ''}`}>
              <div className="centered-faq">
                {allPrismicFaqs.visas.map(
                  (item, index) =>
                    item.title.text && (
                      <Faq key={index} title={item.title.text} description={item.content.html} />
                    )
                )}
              </div>
            </div>
            <div className={`inner-wrapper ${courseType === 'accomodation' ? 'active' : ''}`}>
              <div className="centered-faq">
                {allPrismicFaqs.accommodation.map(
                  (item, index) =>
                    item.title.text && (
                      <Faq key={index} title={item.title.text} description={item.content.html} />
                    )
                )}
              </div>
            </div>
            <div
              className={`inner-wrapper ${courseType === 'working-in-barcelona' ? 'active' : ''}`}
            >
              <div className="centered-faq">
                {allPrismicFaqs.payment.map(
                  (item, index) =>
                    item.title.text && (
                      <Faq key={index} title={item.title.text} description={item.content.html} />
                    )
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicFaqs {
      edges {
        node {
          data {
            accommodation {
              title {
                text
              }
              content {
                html
              }
            }
            ila_courses {
              content {
                html
              }
              title {
                text
              }
            }
            payment {
              content {
                html
              }
              title {
                text
              }
            }
            visas {
              title {
                text
              }
              content {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default FaqPage;
